.img_banner {
  height: auto;
  width: 100%;
}

.btn_container {
  display: flex;
  justify-content: center;
}
.btn_background {
  background: black;
  color: #f7d4db;
  border: none;
  font-size: 28px;
  position: relative;
  bottom: 70px;
  width: 221px;
  border-radius: 4px;
}
