@import url("https://fonts.googleapis.com/css?family=Raleway:400,600,900");

.thankYou {
  background: #000000;
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* background: url("https://hips.hearstapps.com/hmg-prod/images/victorias-secret-fashion-show-1544794703.jpg?crop=0.501xw:1.00xh;0.209xw,0&resize=2048:*");
  background-size: cover; */

  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.3s linear 0s;
  }

  .wrapper-1 {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .wrapper-2 {
    padding: 30px;
    text-align: center;
    height: 100vh;
  }

  h1 {
    font-family: "Raleway", Arial Black, Sans-Serif;
    font-size: 4em;
    font-weight: 900;
    letter-spacing: 3px;
    color: #fafafa;
    margin: 0;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .wrapper-2 p {
    margin: 0;
    font-size: 1.3em;
    color: #fafafa;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1px;
    line-height: 1.5;
  }

  .go-home {
    background: #e83890;
    border: none;
    padding: 25px 30px;
    margin: 30px 0;
    border-radius: 5px;
    cursor: pointer;
    z-index: 12;
    position: relative;
  }

  .go-home:hover {
    opacity: 0.9;
  }

  .go-home {
    font-family: "Raleway", Arial Black;
    font-size: 1rem;
    font-weight: 700 !important;
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @media (min-width: 360px) {
    h1 {
      font-size: 4.5em;
    }

    .go-home {
      margin-bottom: 20px;
    }
  }

  @media (min-width: 600px) {
    .thankyoucontent {
      max-width: 1000px;
      margin: 0 auto;
    }

    .wrapper-1 {
      height: initial;
      max-width: 620px;
      margin: 0 auto;
      margin-top: 50px;
    }
  }
}
